import Image from 'next/image';
import HeroImg from '@/imgs/catalog/auto_bg_catalog.jpg';
import HeroInputs from '@/components/Main/shared/HeroInputs';
import { switchCountry } from '@/shared/helpers';

export default function HeroBlock({ country = 'usa' }) {
  return (
    <div className="hero-block">
      <div className="bg">
        <Image
          src={HeroImg.src}
          fill
          priority={true}
          placeholder="blur"
          alt="main bg"
          blurDataURL={HeroImg.blurDataURL}
        />
      </div>
      <div className="hero-block__content">
        <h1 className="hero-block__title-h1">
          Авто из {switchCountry(country)} <br />
        </h1>
        <h2 className="hero-block__title-h2">c проверкой перед покупкой</h2>
        <h3 className="hero-block__title-h3">и прозрачным оформлением</h3>

        <div className="hero-block__subtitle">
          <p>Оставьте номер и мы пришлем варианты в whatsapp под ваш бюджет!</p>
        </div>
        <HeroInputs country={country} />
      </div>
    </div>
  );
}
